import axios from "axios";
import { request } from "@/utils/request";

/**
 * 查询
 * @param {*} data
 * @returns
 */
export function selectStaffById(data) {
  return request({
    url: `/admin/staff/selectById`,
    method: "POST",
    data,
  });
}

/**
 * 新增
 * @param {*} data
 * @returns
 */
export function addStaff(data) {
  return request({
    url: `/admin/staff/add`,
    method: "POST",
    data,
  });
}

/**
 * 更改
 * @param {*} data
 * @returns
 */
export function updateStaff(data) {
  return request({
    url: `/admin/staff/update`,
    method: "POST",
    data,
  });
}

/**
 * 编辑权限
 * @param {*} data
 * -role_id
 * @returns
 */
export function updateRole(data) {
  return request({
    url: `/admin/staff/updateRole`,
    method: "POST",
    data,
  });
}
/**
 * 删除
 * @param {*} data
 * @returns
 */
export function deleteStaff(data) {
  return request({
    url: `/admin/staff/delete`,
    method: "POST",
    data,
  });
}
