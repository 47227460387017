export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "店铺名称", prop: "store_id", slots: "store_id", width: 230 },
    { label: "员工姓名", prop: "staff_name", width: 150 },
    { label: "员工手机号", prop: "staff_phone" },
    { label: "身份", prop: "role", slots: "role" },
    // { label: "员工绑定", prop: "", width: 150 },
    // { label: "创建人ID", prop: "admin_id" },
    {
      label: "创建时间",
      prop: "create_time",
      width: 180,
    },
    {
      label: "操作",
      prop: "operation",
      width: 150,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
