<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <el-form :inline="true">
      <el-button type="primary" size="small" v-waves @click="handleAdd"
        >新增</el-button
      >
      <el-form-item label="所属门店:" style="margin:0 20px" size="small">
        <el-select
          v-model="listQuery.store_id"
          placeholder="请选择"
          clearable
          multiple
          collapse-tags
        >
          <div v-for="(item, index) in filterStoreList" :key="index">
            <el-option
              :label="item.store_name"
              :value="item.store_id + ''"
            ></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号: " style="margin-right:20px" size="small">
        <el-input
          v-model="listQuery.staff_phone"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="getListData" size="small" v-waves
        >搜索</el-button
      >
    </el-form>

    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="String(row.store_id)" />
        </template>
        <template slot="role" slot-scope="{ row }">
          <el-tag v-if="row.role === 1" type="danger" effect="dark" size="mini">
            员工（仅查看）
          </el-tag>
          <el-tag v-if="row.role === 2" effect="dark" size="mini">
            员工（可操作）
          </el-tag>
          <el-tag
            v-if="row.role === 3"
            type="success"
            effect="dark"
            size="mini"
          >
            店长(可以管理员工)
          </el-tag>
        </template>
        <!-- <template slot="state" slot-scope="scope">
           <FillingStatus :value="scope.row" />
        </template>
        <template slot="fillRate" slot-scope="scope">
          <Percentage :value="scope.row"></Percentage>
        </template> -->
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button size="mini" type="text" @click="handleEdit(row)" primary
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
      <!-- 弹窗表单 -->
      <el-dialog
        :title="dialogName"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="90px">
          <el-form-item label="分配门店:" required>
            <el-select
              v-model="dialogForm.store_id"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <div v-for="(item, index) in filterStoreList" :key="index">
                <el-option
                  :label="item.store_name"
                  :value="item.store_id"
                ></el-option>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="权限:" required>
            <el-select
              v-model="dialogForm.role"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <div v-for="(item, index) in roleList" :key="index">
                <el-option :label="item.name" :value="item.id"></el-option>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="员工姓名" required>
            <el-input clearable v-model="dialogForm.staff_name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" required>
            <el-input
              clearable
              v-model="dialogForm.staff_phone"
              placeholder="格式为11位数手机号"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button class="addBtn" size="small" type="primary" @click="save" v-waves
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import StoreToString from "@/components/filters/storeToString.vue";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import {
  selectStaffById,
  updateStaff,
  deleteStaff,
  addStaff,
} from "@/services/mall/staff";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
    StoreToString,
  },
  async created() {
    this.getListData();
  },
  data() {
    return {
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      /* 弹窗 */
      dialogName: "",
      dialogVisible: false,
      dialogForm: { store_id: "" },
      roleList: [
        { id: 1, name: "员工（仅查看）" },
        { id: 2, name: "员工（可操作）" },
        { id: 3, name: "店长(在小程序上可以管理员工)" },
      ],
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.store_id =
        listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectStaffById({
        ...(listQuery || this.listQuery),
        admin_id: this.userInfo.id,
      });
      // console.log("============getListData===============", data);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 新增 */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogName = "创建员工";
      this.dialogForm = {};
    },

    /* 修改 */
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.dialogName = "编辑员工";
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },

    /* 保存 */
    save() {
      //必填字段校验
      if (
        !this.dialogForm.role ||
        !this.dialogForm.staff_name ||
        !this.dialogForm.staff_phone ||
        !this.dialogForm.store_id
      ) {
        this.$message.error("请填写必填字段");
        return;
      }
      /* 账号格式校验 */
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.dialogForm.staff_phone)) {
        this.$message.error("手机号格式错误");
        return;
      }

      if (this.dialogName === "创建员工") {
        this.dialogForm.admin_id = this.userInfo.id;
        addStaff(this.dialogForm).then((res) => {
          console.log("创建员工", res);
          if (res.code === 200) {
            this.listQuery.pageNumber = 1;
            this.getListData();
            this.$message({
              type: "success",
              message: "创建成功!",
            });
            this.dialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      }
      if (this.dialogName === "编辑员工") {
        updateStaff(this.dialogForm).then((res) => {
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(
        `此操作将永久删除'${row.staff_name}'员工吗, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteStaff({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
